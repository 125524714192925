<template>
  <div class="mb-3 card transparent">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <BaseDateInput v-model="startDateTime" :disabled="this.dates_loading"
                        :label="$t('accounting.lang_dailyReportStart')"
                        :loading="this.dates_loading"
                        :rules="[v => !!v]" dense outlined
                        type="date"/>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <BaseDateInput v-model="endDateTime" :disabled="this.dates_loading"
                        :label="$t('accounting.lang_dailyReportEnd')"
                        :loading="this.dates_loading"
                        :rules="[v => !!v]" dense outlined
                        type="date"/>
        </v-col>
        <v-col class="pt-0" cols="12" md="10" sm="8">
          <v-select :loading="this.departments_loader" outlined dense clearable
                    :label="$t('customers.lang_customerDepartments')" :items="this.departments"
                    item-value="id" item-text="name" v-model="customer_department"/>
        </v-col>
        <v-col class="pt-0 pb-2" cols="12" md="2" sm="4">
          <v-btn block class="mx-auto elevation-0" color="primary" @click="filter()">
            <v-icon>filter_list</v-icon>
            {{ $t('generic.lang_filter') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div>


      <!-- Table -->

      <Datatable v-if="showTable" ref="unpaidInvoicesTable"
                 :api-endpoint="ENDPOINTS.DATATABLES.ACCOUNTING.DEBITINVOICE.UNPAID"
                 :datatable-headers="datatableHeaders"
                 :excel-columns="excelColumns"
                 excel-file-name="Offene_Sammelrechnungen"
                 @displayEntry="displayEntry"
                 v-bind:data="this.params"
                 show-display-buttons
                 show-selected-pdf
                 @downloadSelectedPdf="downloadSelected" :pdf-selected-loading="loadingSelectedPDF"
      >
        <template v-slot:item.total="{item}">
          {{item.total | currency}}
        </template>
      </Datatable>


    </div>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>


<script>
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from '@/config'
import mixin from "@/mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import moment from "moment";
import FileSaver from 'file-saver';
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: 'UnpaidDebitInvoicesComponent',
  components: {
    Datatable,
    BaseDateInput
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      dates_loading: false,
      departments_loader: false,
      departments: [],
      startDateTime: null,
      endDateTime: null,
      datatableHeaders: [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "id",
          hide: true
        },
        {text: this.$t('generic.lang_id'), value: "ident", sort: "desc"},
        {text: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('generic.lang_seller_id'), value: "sellerid"},
        {text: this.$t('generic.lang_customer'), value: "cust"},
        {text: this.$t('erp.lang_totalsolditems'), value: "saleItems"},
        {text: this.$t('erp.lang_ware_total') , value: "total"},
        {text: this.$t('generic.lang_TableNo'), value: "tableNo"},
        {text: this.$t('generic.lang_parMaison'), value: "parMaison"},
        {text: this.$t('generic.lang_cashierID'), value: "cashierID"},
        {text: this.$t('generic.lang_receipttype'), value: "type"},
      ],
      excelColumns: [
        {label: this.$t('generic.lang_id'), field: "ident"},
        {label: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), field: "date"},
        {label: this.$t('generic.lang_seller_id'), field: "sellerid"},
        {label: this.$t('generic.lang_customer'), field: "cust"},
        {label: this.$t('erp.lang_totalsolditems'), field: "saleItems"},
        {label: this.$t('erp.lang_ware_total'), field: "total"},
        {label: this.$t('generic.lang_TableNo'), field: "tableNo"},
        {label: this.$t('generic.lang_parMaison'), field: "parMaison"},
        {label: this.$t('generic.lang_cashierID'), field: "cashierID"},
        {label: this.$t('generic.lang_receipttype'), field: "type"},
      ],

      id: null,
      voucherBalance: "",
      issuingDate: null,
      lastUse: null,
      voucherBalanceAdd: "",
      customerIdAdd: null,
      addSearch: null,
      items: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      voucherData: [],
      dataTable: null,
      customer_department: "",
      loadingSelectedPDF:false,
      pdfSelectLimit:15, // the max number of selected invoices to export
    }
  },
  computed: {
    params: function () {
      return {

        start_date: this.startDateTime === "" ? "" : new Date(this.startDateTime).getTime() / 1000,
        end_date: this.endDateTime === "" ? "" : moment.unix(new Date(this.endDateTime).getTime() / 1000).endOf("days").unix(),
        department: this.customer_department
      }

    }
  },
  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    },
    customer_department() {
      const self = this;
      let timer = setTimeout(function () {
        self.$refs.unpaidInvoicesTable.getDataFromApi();
        clearTimeout(timer)
      }, 300)
    },
    startDateTime(val) {
      if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() >= new Date(val).getTime()) {
        //this.$refs.salesTable.getDataFromApi();
        //this.loadData();
      } else if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() <= new Date(val).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_before') + " : " + this.endDateTime
        });
        this.startDateTime = "";
      }
    },
    endDateTime(val) {
      if (this.startDateTime != "" && this.startDateTime != null && new Date(this.startDateTime).getTime() <= new Date(val).getTime()) {
        //this.loadData();
      } else if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() <= new Date(val).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_after') + " : " + this.startDateTime
        });
        this.endDateTime = "";
      }
    },
  },

  methods: {
    loadDepartments() {
      this.departments_loader = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.GET_ALL).then((res) => {
        if (Array.isArray(res.data.results))
          this.departments = res.data.results.map((dp) => ({
            id: dp.id,
            name: dp.departmentName
          }))
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.departments_loader = false;
      })
    },
    displayEntry(entry) {
      this.uuid = entry.id;
      this.text = entry.name;
      this.$router.push({name: 'accounting.DisplayUnpaidInvoice', params: {id: this.uuid}})
    },

    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },

    filter() {
      //if (!this.$refs.form.validate()) return;
      this.$refs.unpaidInvoicesTable.getDataFromApi();
    },
    downloadSelected(ids){
      if(ids && ids.length>this.pdfSelectLimit){
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_downloadLimit')+' '+this.pdfSelectLimit+' '+this.$t('generic.lang_selected')+' '+ids.length,
          color: "warning"
        });
        this.$refs.unpaidInvoicesTable.resetSelectedRows();
        return;
      }
      this.loadingSelectedPDF = true;
      this.axios.post("get/accounting/bulkInvoiceDataPDF/", {
            invoiceUUIDS:ids
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Invoice" + ".pdf");
        this.$refs.unpaidInvoicesTable.resetSelectedRows();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSelectedPDF = false;
      })
    },

  },
  mounted() {
    this.loadDepartments();
  }
}
</script>
