<template>
    <div>
        <page-title :heading="this.$t('accounting.lang_unpaid_debitInvoices')" :subheading="this.$t('accounting.lang_unpaid_debitInvoices')" :icon=icon></page-title>
        <div class="app-main__inner">
          <UnpaidDebitInvoicesComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "@/Layout/Components/PageTitle.vue";
    import UnpaidDebitInvoicesComponent from "@/components/accounting/debitinvoice/UnpaidDebitInvoicesComponent";

    export default {
        components: {
          UnpaidDebitInvoicesComponent,
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-bookmarks icon-gradient bg-tempting-azure',

        }),
    }
</script>